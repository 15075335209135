import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';

export default function Template({ data }) {
  const { markdownRemark: post } = data;
  console.log('data:', data);
  return (
    <Layout>
      <article>
        <Helmet>
          <title>{`${post.frontmatter.title} | ${
            data.site.siteMetadata.title
          }`}</title>
          <link rel="canonical" href={post.frontmatter.path} />
        </Helmet>

        <header className="hero is-bold is-primary is-medium">
          <div className="hero-body container">
            <h1 className="title">{post.frontmatter.title}</h1>
          </div>
        </header>
        <main
          className="container"
          style={{
            padding: '2em'
          }}
        >
          <p className="title is-6 has-text-centered">
            By {post.frontmatter.author}
          </p>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </main>
      </article>
    </Layout>
  );
}

export const postQuery = graphql`
  query BlogPostByPath($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        author
        tags
      }
    }
  }
`;
